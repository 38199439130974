@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.app .top-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  width: 140px;
  height: 40px;
  border-radius: 0;
  border: 3px solid black;
  font-weight: 500;
  font-size: 18px;
  color: black;
  background: rgba(0, 0, 0, 0);
  font-family: "Anton", sans-serif;
  cursor: pointer;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  letter-spacing: 1px;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .top-button {
    display: none;
  }
}
.app .top-button:hover {
  background: black;
  color: white;
}
.app .header {
  background: #f57600;
  padding: 2vmin;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .header {
    padding: 1vmin;
  }
}
.app .header .logo {
  display: flex;
  align-items: center;
  transform: rotate(-8deg);
}
.app .header .logo h1 {
  position: relative;
  display: inline-block;
  border-right: 2px solid black;
}
.app .header .logo h1 .line-one, .app .header .logo h1 .line-two {
  display: block;
  line-height: 0.85;
}
.app .header .logo h1 .line-one {
  font-size: 2.6rem;
  font-weight: 800;
  color: black;
  font-family: "Barlow", sans-serif;
  position: absolute;
  right: 4px;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .header .logo h1 .line-one {
    font-size: 2rem;
  }
}
.app .header .logo h1 .line-two {
  font-size: 2rem;
  font-family: "Rock Salt", cursive;
  color: #F1E8DB;
  margin-top: 2.2rem;
  padding: 0 9px 0 0;
  line-height: 1;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .header .logo h1 .line-two {
    font-size: 1.5rem;
    margin-top: 1.6rem;
    padding: 0 5px 0 0;
  }
}
.app .header .logo h2 {
  font-size: 1.3rem;
  line-height: 0.85;
  margin-left: 9px;
  font-family: "Barlow", sans-serif;
  font-weight: 800;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .header .logo h2 {
    margin-left: 4px;
  }
}
.app .header .menu {
  position: relative;
}
.app .header .menu .nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .app .header .menu .nav-menu {
    flex-direction: column;
  }
}
.app .header .menu .nav-menu .nav-list {
  display: flex;
  list-style: none;
  font-size: 28px;
  font-weight: 500;
  font-family: "Anton", sans-serif;
  color: #F1E8DB;
  letter-spacing: 1px;
  position: relative;
}
@media screen and (max-width: 800px) {
  .app .header .menu .nav-menu .nav-list {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f57600;
    z-index: 1001;
    margin: 0;
    padding: 0;
  }
}
.app .header .menu .nav-menu .nav-list li {
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .app .header .menu .nav-menu .nav-list li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
.app .header .menu .nav-menu .nav-list li:hover {
  color: black;
}
.app .header .menu .nav-menu .nav-list li:not(:last-child) {
  margin-right: 35px;
}
@media screen and (max-width: 800px) {
  .app .header .menu .nav-menu .nav-list li:not(:last-child) {
    margin-right: 0;
  }
}
.app .header .menu .nav-menu .nav-list.open {
  display: flex;
}
.app .header .menu .nav-menu .hamburger {
  display: none;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}
.app .header .menu .nav-menu .hamburger:hover {
  color: black;
}
@media screen and (max-width: 800px) {
  .app .header .menu .nav-menu .hamburger {
    display: block;
  }
}
.app .header .menu .nav-menu .close-button {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 36px;
  color: #F1E8DB;
  background: none;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .app .header .menu .nav-menu .close-button {
    display: block;
  }
}
.app .header .menu .nav-menu .close-button:hover {
  color: black;
}
.app .marquee-container {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
  background: #F1E8DB;
  display: flex;
  align-items: center;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}
.app .marquee-container .marquee-item {
  min-width: 125px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 10px;
}
.app .hero {
  background: #f57600;
  padding: 4vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
}
@media screen and (max-width: 768px) {
  .app .hero {
    min-height: auto;
    padding: 6vmin;
  }
}
.app .hero .hero-main {
  display: flex;
  align-items: center;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .app .hero .hero-main {
    flex-direction: column;
    justify-content: center;
    padding: 8vmin 0 0 0;
  }
}
.app .hero .hero-main .hero-main-text {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .app .hero .hero-main .hero-main-text {
    width: 100%;
  }
}
.app .hero .hero-main .hero-main-text h2 {
  font-family: "Anton", sans-serif;
  font-size: 8rem;
  line-height: 1.03;
  margin-bottom: 10px;
  font-weight: 800;
}
@media screen and (max-width: 1400px) {
  .app .hero .hero-main .hero-main-text h2 {
    font-size: 6.8rem;
  }
}
@media screen and (max-width: 1200px) {
  .app .hero .hero-main .hero-main-text h2 {
    font-size: 5.6rem;
  }
}
@media screen and (max-width: 992px) {
  .app .hero .hero-main .hero-main-text h2 {
    font-size: 4.2rem;
  }
}
@media screen and (max-width: 768px) {
  .app .hero .hero-main .hero-main-text h2 {
    font-size: 4.6rem;
  }
}
.app .hero .hero-main .hero-main-text h3 {
  font-family: "Barlow", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 0.9;
  margin-bottom: 20px;
  width: 80%;
}
@media screen and (max-width: 1400px) {
  .app .hero .hero-main .hero-main-text h3 {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 1200px) {
  .app .hero .hero-main .hero-main-text h3 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 576px) {
  .app .hero .hero-main .hero-main-text h3 {
    font-size: 2.2rem;
    width: 90%;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .hero .hero-main .hero-main-text h3 {
    font-size: 1.8rem;
  }
}
.app .hero .hero-main .hero-main-text .accent {
  color: #F1E8DB;
}
.app .hero .hero-main .hero-main-text .contact-btn {
  width: 210px;
  height: 75px;
  border-radius: 0;
  border: 3px solid black;
  font-weight: 500;
  font-size: 28px;
  color: black;
  background: #F1E8DB;
  box-shadow: 8px 8px 0 0;
  position: relative;
  font-family: "Anton", sans-serif;
  cursor: pointer;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  letter-spacing: 1px;
}
.app .hero .hero-main .hero-main-text .contact-btn:hover {
  background: rgba(0, 0, 0, 0);
}
.app .hero .hero-main .hero-main-text .contact-btn:active {
  box-shadow: 0px 0px 0px 0px;
  top: 8px;
  left: 8px;
}
.app .hero .hero-main .hero-main-img {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 25px 0;
}
@media screen and (max-width: 768px) {
  .app .hero .hero-main .hero-main-img {
    width: 100%;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .hero .hero-main .hero-main-img {
    margin-top: 2rem;
  }
}
.app .hero .hero-main .hero-main-img img {
  height: 120%;
  width: 80%;
  transform: rotate(5deg);
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (max-width: 992px) {
  .app .hero .hero-main .hero-main-img img {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .app .hero .hero-main .hero-main-img img {
    width: 90%;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .hero .hero-main .hero-main-img img {
    width: 100%;
  }
}
.app .hero .hero-main .hero-main-img img:hover {
  transform: rotate(-5deg);
}
.app .main .page {
  width: 100%;
  display: flex;
}
.app .main .page .accent {
  color: #F1E8DB;
}
.app .main .page .accent-two {
  color: #f57600;
}
.app .main .page .section-title {
  font-size: 4.4rem;
  font-family: "Anton", sans-serif;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .app .main .page .section-title {
    font-size: 3.2rem;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .page .section-title {
    font-size: 2.7rem;
  }
}
.app .main .info {
  background: #F1E8DB;
  display: flex;
  flex-direction: column;
  border-top: 3px solid black;
}
.app .main .info .section-title {
  margin-top: 3rem;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .section-title {
    margin-top: 2rem;
  }
}
.app .main .info .process-main {
  width: 100%;
  padding: 4vmin 4vmin 8vmin 4vmin;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .process-main {
    padding: 6vmin;
  }
}
.app .main .info .process-main .sh {
  font-weight: 600;
}
.app .main .info .process-main p {
  font-size: 1.5rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .process-main p {
    font-size: 1.4rem;
  }
}
.app .main .info .process-main .process-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;
}
@media screen and (max-width: 1200px) {
  .app .main .info .process-main .process-container {
    margin: 1rem 0;
  }
}
.app .main .info .process-main .process-container .process-item {
  width: 33.3333333333%;
  display: flex;
  flex-direction: column;
  padding: 15px 50px;
}
@media screen and (max-width: 1400px) {
  .app .main .info .process-main .process-container .process-item {
    padding: 10px 20px;
  }
}
@media screen and (max-width: 1200px) {
  .app .main .info .process-main .process-container .process-item {
    width: 50%;
  }
}
@media screen and (max-width: 992px) {
  .app .main .info .process-main .process-container .process-item {
    width: 100%;
    padding: 20px;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .process-main .process-container .process-item {
    padding: 20px 0;
  }
}
.app .main .info .process-main .process-container .process-item .process-item-header {
  display: flex;
  align-items: end;
  justify-content: space-between;
  border-bottom: 3px solid black;
  padding-bottom: 5px;
}
.app .main .info .process-main .process-container .process-item .process-item-header h3 {
  font-family: "Anton", sans-serif;
  font-size: 2rem;
  letter-spacing: 1px;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .process-main .process-container .process-item .process-item-header h3 {
    font-size: 2.2rem;
  }
}
.app .main .info .process-main .process-container .process-item .process-item-header img {
  width: 80px;
  height: 80px;
}
.app .main .info .process-main .process-container .process-item .process-item-content {
  padding-top: 5px;
}
.app .main .info .process-main .process-container .process-item .process-item-content p {
  font-size: 1.5rem;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .process-main .process-container .process-item .process-item-content p {
    font-size: 1.4rem;
  }
}
.app .main .info .about-main {
  background: #f57600;
  display: flex;
  border-top: 3px solid black;
}
@media screen and (max-width: 1200px) {
  .app .main .info .about-main {
    flex-direction: column-reverse;
  }
}
.app .main .info .about-main .about-img {
  width: 50%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .app .main .info .about-main .about-img {
    width: 100%;
  }
}
.app .main .info .about-main .about-img img {
  width: 100%;
  height: 102%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: rotate(-3deg);
  z-index: 1000;
}
@media screen and (max-width: 1200px) {
  .app .main .info .about-main .about-img img {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .app .main .info .about-main .about-img img {
    width: 80%;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .about-main .about-img img {
    width: 94%;
  }
}
.app .main .info .about-main .about-img img:hover {
  transform: rotate(3deg);
}
.app .main .info .about-main .about-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 6vmin;
  margin: 2rem 0 0 0;
}
@media screen and (max-width: 1200px) {
  .app .main .info .about-main .about-content {
    width: 100%;
    align-items: center;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .about-main .about-content {
    align-items: start;
  }
}
.app .main .info .about-main .about-content .section-title {
  margin: 0 0 1rem 0;
}
.app .main .info .about-main .about-content .about-item {
  margin: 20px 0;
  text-align: justify;
}
@media screen and (max-width: 1200px) {
  .app .main .info .about-main .about-content .about-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .about-main .about-content .about-item {
    align-items: start;
  }
}
.app .main .info .about-main .about-content .about-item .about-item-title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-family: "Anton", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .about-main .about-content .about-item .about-item-title {
    font-size: 1.8rem;
  }
}
.app .main .info .about-main .about-content .about-item .about-item-title .about-icon {
  margin-right: 8px;
}
.app .main .info .about-main .about-content .about-item .about-item-text {
  width: 80%;
  font-family: "Barlow", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}
@media screen and (max-width: 1200px) {
  .app .main .info .about-main .about-content .about-item .about-item-text {
    text-align: center;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .info .about-main .about-content .about-item .about-item-text {
    width: 100%;
    text-align: start;
    font-size: 1.4rem;
  }
}
.app .main .work {
  background: #f57600;
  padding: 4vmin 4vmin 8vmin 4vmin;
  border-bottom: 3px solid black;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .work {
    padding: 6vmin 6vmin 8vmin 6vmin;
  }
}
.app .main .work .work-main {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .app .main .work .work-main {
    flex-direction: column;
  }
}
.app .main .work .work-main .testimonial {
  display: flex;
  align-items: end;
  width: 30%;
}
@media screen and (max-width: 1200px) {
  .app .main .work .work-main .testimonial {
    width: 50%;
    margin-top: 3rem;
  }
}
@media screen and (max-width: 992px) {
  .app .main .work .work-main .testimonial {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .app .main .work .work-main .testimonial {
    width: 100%;
    margin-top: 2rem;
  }
}
.app .main .work .work-main .testimonial blockquote {
  font-size: 1.5rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .work .work-main .testimonial blockquote {
    font-size: 1.4rem;
  }
}
.app .main .work .work-main .testimonial blockquote p {
  margin-bottom: 1rem;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.app .main .work .work-main .testimonial blockquote figcaption {
  display: flex;
  align-items: center;
}
.app .main .work .work-main .testimonial blockquote figcaption a {
  cursor: pointer;
  -webkit-text-decoration: 2px dotted underline #F1E8DB;
          text-decoration: 2px dotted underline #F1E8DB;
  color: black;
  margin-left: 3px;
}
.app .main .work .work-main .testimonial blockquote figcaption a:hover {
  color: #F1E8DB;
}
.app .main .work .work-main .testimonial blockquote figcaption .testimonial-icon {
  font-size: 14px;
  margin-left: 5px;
}
.app .main .work .work-main .projects {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
}
@media screen and (max-width: 1200px) {
  .app .main .work .work-main .projects {
    width: 100%;
  }
}
.app .main .work .work-main .projects .section-title {
  margin: 2rem 0 0 0;
}
@media screen and (max-width: 768px) {
  .app .main .work .work-main .projects .section-title {
    margin: 2rem 0 2rem 0;
  }
}
.app .main .work .work-main .projects .sh {
  font-size: 1.5rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  width: 50%;
}
@media screen and (max-width: 1200px) {
  .app .main .work .work-main .projects .sh {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .app .main .work .work-main .projects .sh {
    width: 100%;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .work .work-main .projects .sh {
    font-size: 1.4rem;
  }
}
.app .main .work .work-main .projects .projects-list {
  display: flex;
  margin: 3rem 0 0 0;
}
@media screen and (max-width: 768px) {
  .app .main .work .work-main .projects .projects-list {
    flex-direction: column;
    margin: 2rem 0 0 0;
  }
}
.app .main .work .work-main .projects .projects-list .project {
  width: 350px;
  height: 250px;
  border: 3px solid black;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}
@media screen and (max-width: 1200px) {
  .app .main .work .work-main .projects .projects-list .project {
    width: 375px;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .work .work-main .projects .projects-list .project {
    width: 100%;
    height: 275px;
  }
}
.app .main .work .work-main .projects .projects-list .project:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 15px #F1E8DB;
}
.app .main .work .work-main .projects .projects-list .project:first-child {
  margin-right: 2rem;
}
@media screen and (max-width: 768px) {
  .app .main .work .work-main .projects .projects-list .project:first-child {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
}
.app .main .work .work-main .projects .projects-list .project .project-bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s;
}
.app .main .work .work-main .projects .projects-list .project .project-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F1E8DB;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  transition: opacity 0.3s;
}
.app .main .work .work-main .projects .projects-list .project .project-content h3 {
  margin-bottom: 20px;
  font-size: 2.6rem;
  font-family: "Anton", sans-serif;
  line-height: 1;
}
.app .main .work .work-main .projects .projects-list .project .project-content p {
  font-size: 1.5rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  color: black;
  margin-bottom: 0.5rem;
}
.app .main .work .work-main .projects .projects-list .project .project-content .project-button {
  display: inline-block;
  width: 225px;
  height: 65px;
  border-radius: 0;
  border: 3px solid black;
  font-weight: 500;
  font-size: 28px;
  color: black;
  background: #F1E8DB;
  box-shadow: 8px 8px 0 0;
  position: relative;
  font-family: "Anton", sans-serif;
  cursor: pointer;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  letter-spacing: 1px;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .work .work-main .projects .projects-list .project .project-content .project-button {
    font-size: 24px;
  }
}
.app .main .work .work-main .projects .projects-list .project .project-content .project-button:hover {
  background: #f57600;
}
.app .main .work .work-main .projects .projects-list .project .project-content .project-button:active {
  box-shadow: 0px 0px 0px 0px;
  top: 8px;
  left: 8px;
}
.app .main .work .work-main .projects .projects-list .project .project-content .project-button .project-button-icon {
  margin-left: 5px;
}
.app .main .work .work-main .projects .projects-list .project:hover .project-bg-image {
  opacity: 0;
}
.app .main .work .work-main .projects .projects-list .project:hover .project-content {
  opacity: 1;
}
.app .main .services {
  background: #F1E8DB;
  padding: 4vmin;
  border-bottom: 3px solid black;
}
.app .main .services .services-main {
  width: 100%;
}
.app .main .services .services-main .section-title {
  margin: 2rem 0;
  text-align: center;
}
.app .main .services .services-main .services-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.app .main .services .services-main .services-container .service {
  display: flex;
  width: 33.3333333333%;
  flex-direction: column;
  border: 3px solid black;
  margin: 1rem;
  background: #f57600;
}
@media screen and (max-width: 1400px) {
  .app .main .services .services-main .services-container .service {
    width: 45%;
  }
}
@media screen and (max-width: 992px) {
  .app .main .services .services-main .services-container .service {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .app .main .services .services-main .services-container .service {
    width: 80%;
  }
}
@media screen and (max-width: 576px) {
  .app .main .services .services-main .services-container .service {
    width: 90%;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .services .services-main .services-container .service {
    width: 100%;
  }
  .app .main .services .services-main .services-container .service::not(:last-child) {
    margin: 0 0 40px 0;
  }
}
.app .main .services .services-main .services-container .service .service-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 1rem;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .services .services-main .services-container .service .service-header {
    flex-direction: column;
    align-items: start;
  }
}
.app .main .services .services-main .services-container .service .service-header .service-title {
  display: flex;
  font-family: "Anton", sans-serif;
  font-size: 2rem;
  letter-spacing: 1px;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .services .services-main .services-container .service .service-header .service-title {
    font-size: 2.2rem;
  }
}
.app .main .services .services-main .services-container .service .service-header .service-price {
  font-size: 1.4rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  margin-bottom: 7px;
  text-align: end;
}
@media screen and (max-width: 1200px) {
  .app .main .services .services-main .services-container .service .service-header .service-price {
    width: 30%;
  }
}
@media screen and (max-width: 576px) {
  .app .main .services .services-main .services-container .service .service-header .service-price {
    width: 30%;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .services .services-main .services-container .service .service-header .service-price {
    text-align: start;
    width: 100%;
  }
}
.app .main .services .services-main .services-container .service .service-content .service-best-for, .app .main .services .services-main .services-container .service .service-content .service-description {
  font-size: 1.5rem;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  line-height: 1.1;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .services .services-main .services-container .service .service-content .service-best-for, .app .main .services .services-main .services-container .service .service-content .service-description {
    font-size: 1.4rem;
  }
}
.app .main .services .services-main .services-container .service .service-content .service-best-for {
  padding: 1rem;
  border-bottom: 3px solid black;
}
.app .main .services .services-main .services-container .service .service-content .service-best-for span {
  font-weight: 800;
}
.app .main .services .services-main .services-container .service .service-content .service-description {
  padding: 1rem;
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table {
  display: flex;
  flex-direction: column;
  border: 3px solid black;
  border-radius: 0;
  overflow: hidden;
  margin: 1rem;
}
@media screen and (max-width: 1200px) {
  .app .main .services .services-main .services-container .service .service-content .pros-cons-table {
    display: none;
  }
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-header {
  display: flex;
  background-color: #F1E8DB;
  border-bottom: 3px solid black;
  font-size: 1.5rem;
  font-family: "Barlow", sans-serif;
  text-transform: uppercase;
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-header .pros-header, .app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-header .cons-header {
  flex: 1;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-header .pros-header {
  border-right: 3px solid black;
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-body {
  display: flex;
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-body .pros-column, .app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-body .cons-column {
  flex: 1;
  padding: 10px;
  text-align: center;
  background: #F1E8DB;
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-body .pros-column ul, .app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-body .cons-column ul {
  list-style-type: none;
  padding: 0;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-body .pros-column ul li, .app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-body .cons-column ul li {
  margin-bottom: 5px;
}
.app .main .services .services-main .services-container .service .service-content .pros-cons-table .pros-cons-body .pros-column {
  border-right: 3px solid black;
}
.app .main .services .services-main .services-container .book-service {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.3333333333%;
  margin: 1rem;
}
@media screen and (max-width: 1400px) {
  .app .main .services .services-main .services-container .book-service {
    width: 45%;
  }
}
.app .main .services .services-main .services-container .book-service .service-button {
  width: 250px;
  min-width: 250px;
  height: 250px;
  border-radius: 0;
  border: 3px solid black;
  font-weight: 500;
  font-size: 2rem;
  color: black;
  background: #f57600;
  box-shadow: 8px 8px 0 0;
  position: relative;
  font-family: "Anton", sans-serif;
  cursor: pointer;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  letter-spacing: 1px;
}
.app .main .services .services-main .services-container .book-service .service-button:hover {
  background: #F1E8DB;
}
.app .main .services .services-main .services-container .book-service .service-button:active {
  box-shadow: 0px 0px 0px 0px;
  top: 8px;
  left: 8px;
}
.app .main .services .services-main .services-container .book-service .service-button svg {
  margin-top: 20px;
}
.app .main .contact {
  display: flex;
  flex-direction: column;
  background: #f57600;
  padding: 4vmin;
  position: relative;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .contact {
    padding: 6vmin;
  }
}
.app .main .contact .copyright {
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 5px;
  left: 5px;
}
@media screen and (max-width: 1200px) {
  .app .main .contact .copyright {
    width: 70%;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .contact .copyright {
    width: 95%;
    padding: 0 5px;
  }
}
.app .main .contact .contact-main {
  width: 100%;
  display: flex;
}
@media screen and (max-width: 992px) {
  .app .main .contact .contact-main {
    flex-direction: column;
  }
}
.app .main .contact .contact-main .form, .app .main .contact .contact-main .contact-details {
  display: flex;
  justify-content: center;
}
.app .main .contact .contact-main .form {
  width: 40%;
  align-items: center;
  justify-content: end;
}
@media screen and (max-width: 992px) {
  .app .main .contact .contact-main .form {
    width: 100%;
    justify-content: start;
    padding-top: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .app .main .contact .contact-main .form {
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 576px) {
  .app .main .contact .contact-main .form {
    margin-bottom: 6rem;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .contact .contact-main .form {
    margin-bottom: 8rem;
  }
}
.app .main .contact .contact-main .form .contact-form {
  font-family: "Barlow", sans-serif;
}
.app .main .contact .contact-main .form .contact-form .form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.app .main .contact .contact-main .form .contact-form .form-field label {
  font-size: 1.5rem;
  font-weight: 600;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .contact .contact-main .form .contact-form .form-field label {
    font-size: 1.4rem;
  }
}
.app .main .contact .contact-main .form .contact-form .form-field input, .app .main .contact .contact-main .form .contact-form .form-field textarea {
  border: 3px solid black;
  width: 300px;
  font-size: 1.5rem;
  font-family: "Barlow", sans-serif;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .contact .contact-main .form .contact-form .form-field input, .app .main .contact .contact-main .form .contact-form .form-field textarea {
    width: 275px;
    font-size: 1.4rem;
  }
}
.app .main .contact .contact-main .form .contact-form .form-field input {
  height: 40px;
}
.app .main .contact .contact-main .form .contact-form .form-field textarea {
  resize: none;
}
.app .main .contact .contact-main .form .contact-form .form-field input:focus, .app .main .contact .contact-main .form .contact-form .form-field textarea:focus {
  box-shadow: 0 0 40px #F1E8DB;
  outline: none;
}
.app .main .contact .contact-main .form .contact-form .form-success, .app .main .contact .contact-main .form .contact-form .form-error {
  font-size: 1.5rem;
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .contact .contact-main .form .contact-form .form-success, .app .main .contact .contact-main .form .contact-form .form-error {
    font-size: 1.4rem;
  }
}
.app .main .contact .contact-main .form .contact-form .submit-button {
  width: 210px;
  height: 75px;
  border-radius: 0;
  border: 3px solid black;
  font-weight: 500;
  font-size: 28px;
  color: black;
  background: #F1E8DB;
  box-shadow: 8px 8px 0 0;
  position: relative;
  font-family: "Anton", sans-serif;
  cursor: pointer;
  -moz-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  letter-spacing: 1px;
}
.app .main .contact .contact-main .form .contact-form .submit-button:hover {
  background: rgba(0, 0, 0, 0);
}
.app .main .contact .contact-main .form .contact-form .submit-button:active {
  box-shadow: 0px 0px 0px 0px;
  top: 8px;
  left: 8px;
}
.app .main .contact .contact-main .contact-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 60%;
  flex: 1;
}
@media screen and (max-width: 992px) {
  .app .main .contact .contact-main .contact-content {
    width: 100%;
    padding-bottom: 3rem;
    border-bottom: 3px solid black;
  }
}
.app .main .contact .contact-main .contact-content .section-title {
  margin: 2rem 0 1rem 0;
}
.app .main .contact .contact-main .contact-content h3 {
  width: 375px;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.1;
  margin-bottom: 2rem;
}
@media screen and (max-width: 992px) {
  .app .main .contact .contact-main .contact-content h3 {
    width: 100%;
    padding-bottom: 3rem;
    border-bottom: 3px solid black;
  }
}
@media screen and (min-width: 0px) and (max-width: 576px) {
  .app .main .contact .contact-main .contact-content h3 {
    font-size: 1.4rem;
  }
}
.app .main .contact .contact-main .contact-content h4 {
  font-size: 2rem;
  font-family: "Barlow", sans-serif;
  margin-bottom: 1rem;
}
.app .main .contact .contact-main .contact-content .socials a {
  text-decoration: none;
  color: black;
}
.app .main .contact .contact-main .contact-content .socials a .social-icon {
  font-size: 2rem;
}
.app .main .contact .contact-main .contact-content .socials a .social-icon:first-child {
  margin-right: 1rem;
}
.app .main .contact .contact-main .contact-content .socials a .social-icon:hover {
  color: #F1E8DB;
}
.app .main .contact .contact-main .contact-content .contact-details {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.app .main .contact .contact-main .contact-content .contact-details .specs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 1400px) {
  .app .main .contact .contact-main .contact-content .contact-details .specs {
    flex-direction: column;
  }
}
.app .main .contact .contact-main .contact-content .contact-details .specs a, .app .main .contact .contact-main .contact-content .contact-details .specs p {
  font-size: 1.4rem;
  font-family: "Barlow", sans-serif;
  line-height: 1.1;
  letter-spacing: 1px;
}
.app .main .contact .contact-main .contact-content .contact-details .specs a {
  text-decoration: none;
  color: black;
}
.app .main .contact .contact-main .contact-content .contact-details .specs a .hover-white {
  -webkit-text-decoration: 2px dotted underline #F1E8DB;
          text-decoration: 2px dotted underline #F1E8DB;
}
.app .main .contact .contact-main .contact-content .contact-details .specs a .hover-white:hover {
  color: #F1E8DB;
}
.app .main .contact .contact-main .contact-content .contact-details .specs a .no-hover, .app .main .contact .contact-main .contact-content .contact-details .specs a .contact-icon {
  cursor: default;
}
.app .main .contact .contact-main .contact-content .contact-details .specs a:nth-child(1), .app .main .contact .contact-main .contact-content .contact-details .specs a:nth-child(2) {
  margin-bottom: 1rem;
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 540px) and (min-width: 501px) {
  .hero .hero-main .hero-main-text h2 {
    font-size: 4.4rem !important;
  }
  .hero .hero-main .hero-main-text h3 {
    font-size: 1.8rem !important;
  }
}
@media screen and (max-width: 500px) and (min-width: 461px) {
  .hero .hero-main .hero-main-text h2 {
    font-size: 4.1rem !important;
  }
  .hero .hero-main .hero-main-text h3 {
    font-size: 1.8rem !important;
  }
}
@media screen and (max-width: 460px) and (min-width: 431px) {
  .hero .hero-main .hero-main-text {
    min-height: 50%;
  }
  .hero .hero-main .hero-main-text h2 {
    font-size: 3.8rem !important;
  }
  .hero .hero-main .hero-main-text h3 {
    font-size: 1.6rem !important;
  }
}
@media screen and (max-width: 430px) and (min-width: 401px) {
  .hero .hero-main .hero-main-text h2 {
    font-size: 3.55rem !important;
  }
  .hero .hero-main .hero-main-text h3 {
    font-size: 1.6rem !important;
  }
}
@media screen and (max-width: 400px) and (min-width: 355px) {
  .hero .hero-main .hero-main-text {
    padding-top: 2rem;
  }
  .hero .hero-main .hero-main-text h2 {
    font-size: 3.15rem !important;
  }
  .hero .hero-main .hero-main-text h3 {
    font-size: 1.5rem !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 354px) and (min-width: 320px) {
  .hero .hero-main .hero-main-text {
    padding-top: 2rem;
  }
  .hero .hero-main .hero-main-text h2 {
    font-size: 2.84rem !important;
  }
  .hero .hero-main .hero-main-text h3 {
    font-size: 1.6rem !important;
  }
  .tech {
    font-size: 1rem !important;
  }
  .specs a, .specs p {
    font-size: 1.2rem !important;
  }
  .testimonial figcaption {
    font-size: 1.2rem !important;
  }
}/*# sourceMappingURL=styles.css.map */